import React from "react";
import { Box, List, ListItemIcon, styled, Typography } from "@material-ui/core";
import { EGovNavigatorHeader } from "../../components/App/EGovNavigatorHeader";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { Actions } from "../../components/LandingPage/Actions";
import { FormContainer, ImageContainer, StyledActionContainer } from "../../components/Containers";
import { useInstanceApi } from "../../api/PublicHooks";
import referenzprozess from "../../images/referenzprozess.png"
import previewScreenshot from "../../images/preview_screenshot.png"
import { ReactComponent as Check } from "../../images/icons/confirm-plain.svg";
import { LinkPrimary } from "../../components/Styleguide/Link";
import { ButtonLGPrimary } from "../../components/Styleguide/Button";
import { StyledListItem } from "../../components/Styleguide/List";

export function LandingPage() {
    const {create} = useInstanceApi()
    const navigate = useNavigate()

    const submit = () => {
        create({})
            .then(newId => navigate(routes.public.instance(newId).functions(0), {state: true}))
            .catch(err => navigate(routes.public.notAvailable()))
    }

    return <>
        <EGovNavigatorHeader/>
        <FormContainer>
            <Actions>
                <StyledActionContainer style={{ gridArea: 'text' }}>
                    <Typography variant={"h2"}>Vorteile: </Typography>
                    <LandingPageList>
                        <LandingPageListItem>
                            <LandingPageListItemIcon><StyledCheckIcon/></LandingPageListItemIcon>
                            <Typography variant={"body1"}>das Tool zum eCH-0123 Standard</Typography>
                        </LandingPageListItem>
                        <LandingPageListItem>
                            <LandingPageListItemIcon><StyledCheckIcon/></LandingPageListItemIcon>
                            <Typography variant={"body1"}> in 5 Schritten zum Ergebnis </Typography>
                        </LandingPageListItem>
                        <LandingPageListItem>
                            <LandingPageListItemIcon><StyledCheckIcon/></LandingPageListItemIcon>
                            <Typography variant={"body1"}>PDF Download der Eingaben</Typography>
                        </LandingPageListItem>
                        <LandingPageListItem>
                            <LandingPageListItemIcon><StyledCheckIcon/></LandingPageListItemIcon>
                            <Typography variant={"body1"}>einfaches Teilen per Link</Typography>
                        </LandingPageListItem>
                    </LandingPageList>
                    <Box flexGrow={1}/>
                    <ActionButton variant="contained" color="primary" size="large"
                                  data-cy="start"
                                  onClick={submit}>Planung starten</ActionButton>
                </StyledActionContainer>
                <StyledActionContainer style={{ padding: 0, border: 0, gridArea: 'image' }}>
                    <ImageContainer>
                        <LandingImage width="100%" src={previewScreenshot}/>
                        <Typography variant={"caption"} style={{ marginLeft: "16px" }}>Beispiel Auswahl Leistung</Typography>
                    </ImageContainer>
                </StyledActionContainer>
            </Actions>
            <Divider />
            <ImageContainer>
                <Typography variant={"h2"}>Übersicht Referenzprozess nach eCH-0123 Standard</Typography>
                <LandingImage width="100%" src={referenzprozess} alt="Referenzprozess"/>
                <Typography variant={"caption"}>Schritte im Referenzprozess</Typography>
            </ImageContainer>
            <Typography variant={"body1"} style={{ marginBottom: 0 }}>
                Mehr Informationen zum Online-Tool finden sie auf der Webseite <LinkPrimary href="https://www.zh.ch/de/politik-staat/kanton/kantonale-verwaltung/digitale-verwaltung/referenzprozess.html" target="_blank" rel="noopener">Referenzprozess</LinkPrimary> der Staatskanzlei Zürich.
            </Typography>
        </FormContainer>
    </>
}

export const LandingImage = styled('img')({
    maxWidth: "900px",
    marginRight: "auto",
    paddingTop: "10px",
    paddingBottom: "10px"
})

const ActionButton = styled(ButtonLGPrimary)(({theme}) => ({
    justifySelf: 'end',
    alignSelf: 'flex-start',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginLeft: "auto",
    marginRight: "auto"
}))

const Divider = styled('hr')(({theme}) => ({
    backgroundColor: theme.palette.grey["300"],
    borderStyle: "none",
    height: theme.spacing(1),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}))

const StyledCheckIcon = styled(Check)({
    maxWidth: '30px',
    minWidth: '10px',
    maxHeight: '30px',
    minHeight: '10px',
    width: '100%',
    height: '100%'
})

const LandingPageList = styled(List)({
    display: "flex",
    flexDirection: "column"
})

const LandingPageListItem = styled(({...rest}) => <StyledListItem button={false} {...rest}/>)({
    "& > *": {
        margin: 0,
    }
})

const LandingPageListItemIcon = styled(ListItemIcon)({
    minWidth: "35px",
})
